import Header from "./Header/header";
import Footer from "./Footer/footer";
import Breadcrumb from "./Breadcrumb/breadcrumb";
import Loader from "./Loader/loader";
import { LINK } from "../services/Constants/constant";
import {
    LayoutProvider,
    BodyContainer,
    BreadcrumbWrapper,
    TextButton,
    TitleContainer,
} from "./style";

import FiltersRow from "./Filters/FiltersRow";


const Layout = (props) => {
    const TITLE = "Opportunities";

    return (
        <LayoutProvider>
            <Loader isLoaderDisplay={props.isLoaderDisplay} />
            
            <Header
                isHeaderFixed={false}
                onBackClickCallback={props.onBackClickCallback}
                searchInputCallback={props.handleSearchInput}
                searchSubmitCallback={props.handleSearchSubmit}
            />
            <BodyContainer>
                <BreadcrumbWrapper>
                    <Breadcrumb />
                    <TextButton href={`${LINK}`}>Share Additional Data</TextButton>
                </BreadcrumbWrapper>
                <TitleContainer>{props.title || TITLE}</TitleContainer>
                {/* Plan is to define filter component in the Layout section and pass all the data from the page.
                In this project I am reducing logical component and making most of the component as UI component*/}
                <FiltersRow
                    filtersState={props.filtersSelected}
                    filterOptions={props.filterOptions}
                    handleClickFilters={props.handleClickFilters}
                    handleResetFilters={props.handleResetFilters}
                    isSingleSelect={props.singleSelect}
                />
                {props.children}
            </BodyContainer>
            <Footer relative={props.footerRelative} />
        </LayoutProvider>
    );
};

export default Layout;
