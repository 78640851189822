import { memo, useMemo } from "react";
import {
  GraphGrid,
  GridCard,
  InfoBubBtn,
  ComponentTablePagination,
  TablePagContainer,
  HorizontalBar,
  SunBurnChart,
  WorldMap,
  Line,
  RequestInfo,
  CollapsibleCard,
} from "../../../components";
import { ActiveInfo } from "../style.clinicalTrails";
import {
  COLORS_MAP_LEGEND_1,
  bySponsorTypeStackedColorArr,
  byYearColorArr,
} from "../../../services/Constants/constant";
import { useState } from "react";
import { createSunBurstChartData } from "../utils/util";

const ClinicalTrailGraphUI = (props) => {
  const { map } = props.data;
  const [currentPage_trialsByCountry, setCurrentPage_trialsByCountry] =
    useState(1);

  const INFO = {
    Count:
      "Number of trials representing filtered selection from the total count of trials available in the database.",
    TrialsByCountry: `Navigate through the map using two fingers to zoom in and out.`,
    TrialsByPrimarySponsor:
      "Click on the individual sponsor in the table to learn more about the organization.",
    TrialsByYearlyCount: `Click on the graph to know count by year.`,
    TrialsBySponsorType: `Data from official clinical registries around the world.`,
    CollapsibleCard: [
      "Click on each phase to learn more about the number of trials in a particular phase.",
      "Data from official clinical trial registries worldwide.",
    ],
  };

  const NODATATEXT = {
    Count: "",
    TrialsByCountry: "Trials by Country not available for this disease yet",
    TrialsByPrimarySponsor: `Trials by Primary Sponsor not available for this disease yet`,
    TrialsByYearlyCount: `Trials by Yearly Count not available for this disease yet`,
    TrialsBySponsorType: `Trials by Sponsor Type coming soon!`,
    CollapsibleCard: `Trials by Phase, Population Age, and Sample Size not available for this disease yet`,
  };

  const TITLES = {
    TrialsByCountry: "Trials by Country",
    TrialsByYearlyCount: `Trials By Yearly Trial Count`,
    TrialsBySponsorType: `Trials By Sponsor Type`,
    CollapsibleCard: ["Trials By Phase", "By Population Age", "By Sample Size"],
  };

  const TABLE_HEADERS = ["Primary Sponsor Name", "No. of Trials"];

  const worldMapLegendData = [
    {
      color: COLORS_MAP_LEGEND_1[0],
      label: "< 100",
    },
    {
      color: COLORS_MAP_LEGEND_1[1],
      label: "100 - 500",
    },
    {
      color: COLORS_MAP_LEGEND_1[2],
      label: "500 - 1000",
    },
    {
      color: COLORS_MAP_LEGEND_1[3],
      label: "> 1000",
    },
  ];

  const transformTrialsByYearCount = (chartData) => {
    let transformedData = {}
    let start = Number.MAX_SAFE_INTEGER, end = 0
    //Global max, min
    for (let index = 0; index < Object.keys(chartData).length; index++) {
      const tStart = Math.min(...Object.keys(Object.values(chartData)[index]).map((e) =>parseFloat(e)))
      const tEnd = Math.max(...Object.keys(Object.values(chartData)[index]).map((e) =>parseFloat(e)))
      start = start > tStart ? tStart : start
      end = end < tEnd ? tEnd : end
    }
    
    const diff = end - start

    for (let i = 0; i < Object.keys(chartData).length; i++) {
      
      const disease = Object.keys(chartData)[i]
      const data = Object.values(chartData)[i]
      let newData = {}
      
      for (let index = 0; index <= diff; index++) {
        newData[(start + index)] = data[start + index] ? data[start + index] : 0  
      }
      
      transformedData[disease] = newData      
    }
    return {
      data: transformedData,
      startYear: start,
      endYear: end
    }
  }

  const transformTrialsBySponsorTypes = (chartData) => {
    const sponsorTypes = chartData.map((e) => e[0]);
    const trialsCount = chartData.map((e) => e[1]);
    let transformedData = {
      sponsorTypes: sponsorTypes,
      trialsData: [
        {
          disease: props.chartDisease,
          trialsCount: trialsCount,
        },
      ],
    };
    return transformedData;
  };

  const getColor = (object) => {
    if (!object) /* if object null */ return "#F0F0EF";

    if (map.isPiviCountrySelected) {
      /* A country is selected */
      if (object.isMainCountry) return COLORS_MAP_LEGEND_1[1];
      else return COLORS_MAP_LEGEND_1[0];
    } else {
      /* No country is selected */
      if (object.value < 100) return COLORS_MAP_LEGEND_1[0];
      else if (object.value > 100 && object.value < 500)
        return COLORS_MAP_LEGEND_1[1];
      else if (object.value > 500 && object.value < 1000)
        return COLORS_MAP_LEGEND_1[2];
      else if (object.value > 1000) return COLORS_MAP_LEGEND_1[3];
    }
  };

  const [phaseData, ageData, sampleSizeData] = useMemo(() => {
    return [
      createSunBurstChartData(
        props.data.byPhase,
        props.data.children.byPhase,
        // "Trails By Phase"
      ),
      createSunBurstChartData(
        props.data.byAge,
        props.data.children.byAge,
        // "Population Age"
      ),
      createSunBurstChartData(
        props.data.bySampleSize,
        props.data.children.bySampleSize,
        // "Sample Size"
      ),
    ];
  }, [props.data]);

  const { data: byStartYearTransformedData, startYear, endYear } = transformTrialsByYearCount(props.data.byStartYear);
  const sbcData = props.data.children.byAge?.length == 0
                                  ? transformTrialsBySponsorTypes(props.data.bySponsorCategory)
                                  : props.data.children.bySponsorCategory
  const sbcMissingDataCount = sbcData?.trialsData.map(e => e.trialsCount)
                                                  .flat()
                                                  .reduce((count, x) => x == 0 ? count + 1 : count, 0)

  return (
    <>
      {props.isVisible && (
        <GraphGrid>
          <GridCard
            gridRow={`1/3`}
            gridColumn={`1/9`}
            padding={"0 1.2vw"}
            isDataAvailable={true}
            isBlank
          >
            <ActiveInfo>
              <div style={{ display: "flex", alignItems: "center" }}>
                Showing &nbsp;
                <span>
                  {props.data.countOfFilteredTrials}
                  &nbsp;/&nbsp;
                  {props.data.countOfTotalTrials}
                </span>{" "}
                &nbsp; Filtered Clinical Trials
              </div>
              <InfoBubBtn info={INFO.Count} />
            </ActiveInfo>
          </GridCard>

          <GridCard
            gridRow={`3/11`}
            gridColumn={`1/9`}
            info={INFO.TrialsByCountry}
            isDataAvailable={props.data.countOfFilteredTrials > 0}
            noDataText={NODATATEXT.TrialsByCountry}
            title={
              map?.isPiviCountrySelected
                ? `Trials By ` +
                  map?.mapData[
                    currentPage_trialsByCountry - 1
                  ].countryData.find((obj) => obj.isMainCountry).countryName
                : TITLES.TrialsByCountry
            }
          >
            {/* If You don't pass or render data it will show No Data automatically */}
            {map.isPiviCountrySelected ? (
              <ComponentTablePagination
                height={"100%"}
                currentPage={currentPage_trialsByCountry}
                numberOfPage={map.mapData.length}
                handlePageClick={(pageNumber) =>
                  setCurrentPage_trialsByCountry(pageNumber)
                }
              >
                <WorldMap
                  value-suffix="trials"
                  isPiviCountrySelected={map?.isPiviCountrySelected}
                  mapData={map.mapData[currentPage_trialsByCountry - 1]}
                  getColor={getColor}
                  legendData={[
                    {
                      color: COLORS_MAP_LEGEND_1[1],
                      label: map.mapData[
                        currentPage_trialsByCountry - 1
                      ].countryData.find((obj) => obj.isMainCountry)
                        .countryName,
                    },
                    {
                      color: COLORS_MAP_LEGEND_1[0],
                      label: "Surrounding Countries",
                    },
                  ]}
                />
              </ComponentTablePagination>
            ) : (
              <WorldMap
                value-suffix="trials"
                isPiviCountrySelected={map.isPiviCountrySelected}
                mapData={map.mapData === undefined ? null : map?.mapData[0]}
                getColor={getColor}
                legendData={worldMapLegendData}
              />
            )}
          </GridCard>

          <GridCard
            gridRow={`11/17`}
            gridColumn={`1/9`}
            info={INFO.TrialsByPrimarySponsor}
            isDataAvailable={props.data.countOfFilteredTrials > 0}
            noDataText={NODATATEXT.TrialsByPrimarySponsor}
            title={`Trials By Primary Sponsor (${
              (props.currentPage - 1) * 5 + 1
            } - ${props.currentPage * 5})`}
          >
            <ComponentTablePagination
              currentPage={props.currentPage}
              numberOfPage={props.pageCountofTable}
              handlePageClick={props.handlePageClick}
              height={"100%"}
              alwaysShowArrows={true}
            >
              <TablePagContainer
                currentPage={props.currentPage}
                tableHeader={TABLE_HEADERS}
                tableData={props.data.bySponsor}
                tableHeight={"90%"}
                theadTextAlign={"center"}
              />
            </ComponentTablePagination>
          </GridCard>

          <GridCard
            gridRow={`1/9`}
            gridColumn={`9/16`}
            info={INFO.TrialsByYearlyCount}
            isDataAvailable={props.data.countOfFilteredTrials > 0}
            noDataText={NODATATEXT.TrialsByYearlyCount}
            title={TITLES.TrialsByYearlyCount + ` (${startYear} - ${endYear})`}
          >
            <Line 
              data2={byStartYearTransformedData} 
              color={byYearColorArr} 
              />
          </GridCard>

          <GridCard
            gridRow={`9/17`}
            gridColumn={`9/16`}
            info={INFO.TrialsBySponsorType}
            isDataAvailable={props.data.countOfFilteredTrials > 0}
            noDataText={NODATATEXT.TrialsBySponsorType}
            title={TITLES.TrialsBySponsorType}
          >
            <HorizontalBar
              data={sbcData}
              color={bySponsorTypeStackedColorArr}
            />
            <center>
              <div style={{ color: '#6C7073', fontSize: '0.533rem', fontWeight: 300}}>
                {"(Number of data not available): " + sbcMissingDataCount}
              </div>
            </center>
          </GridCard>

          <CollapsibleCard
            gridRow={`1/13`}
            chartDisease={props.chartDisease}
            gridColumn={`16/22`}
            info={INFO.CollapsibleCard}
            isDataAvailable={props.data.countOfFilteredTrials > 0}
            noDataText={NODATATEXT.CollapsibleCard}
            titles={TITLES.CollapsibleCard}
          >
            <SunBurnChart
              data={phaseData}
              chartWidth={"100%"}
              chartHeight={"100%"}
              chatID={"Phase"}
            />

            <SunBurnChart
              data={ageData}
              chartWidth={"100%"}
              chartHeight={"100%"}
              chatID={"Age"}
            />

            <SunBurnChart
              data={sampleSizeData}
              chartWidth={"100%"}
              chartHeight={"100%"}
              chatID={"Size"}
            />
          </CollapsibleCard>

          <GridCard
            gridRow={`13/17`}
            gridColumn={`16/22`}
            isBlank={true}
            isDataAvailable={true}
          >
            <RequestInfo />
          </GridCard>
        </GraphGrid>
      )}
    </>
  );
};

export default memo(ClinicalTrailGraphUI);