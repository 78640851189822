import React from "react";
import {
  CardDetails,
  CardDetailsHeaderRow,
  CardDivider,
  CardHeaderRow,
  CardSummary,
  CardSummaryDescription,
  CardSummaryStatus,
  CardSummaryStatusText,
  CardSummaryTitle,
  CardTrialID,
  CardWrapper,
  Detail,
  DetailHeading,
  DetailWrapperH,
  DetailsContainerH,
  ClickableTextButton,
} from "../style.opportunities";
import { useNavigate, Link } from "react-router-dom";
import { BiSolidRightArrow } from "react-icons/bi";
import {
  ROUTE_OPPORTUNITY_DETAILS,
  opportunityStatusColor,
  opportunityFundingColor,
  tempDownloadData,
} from "services/Constants/constant.js";

import { Download } from "components";

const getOpportunityStatusColor = (status) => {
  if (status in opportunityStatusColor) return opportunityStatusColor[status];

  return opportunityStatusColor["Unavailable"];
};

const getFundingColor = (status) => {
  if (status in opportunityFundingColor) return opportunityFundingColor[status];

  return opportunityFundingColor["Unavailable"];
};

const ListItem = (props) => {
  const navigate = useNavigate();
  const cardData = props.data;
  const [statusColor, statusTextColor] = getOpportunityStatusColor(
    cardData.StatusText
  );
  const [fundedColor, fundedTextColor] = getFundingColor(cardData.fundingText);

  return (
    <CardWrapper key={cardData.ID}>
      <CardSummary>
        <CardHeaderRow>
          <CardSummaryStatus statusColor={statusColor}>
            <CardSummaryStatusText color={statusTextColor}>
              {cardData.StatusText}
            </CardSummaryStatusText>
          </CardSummaryStatus>
          <CardSummaryStatus statusColor={fundedColor}>
            <CardSummaryStatusText color={fundedTextColor}>
              {cardData.fundingText}
            </CardSummaryStatusText>
          </CardSummaryStatus>
        </CardHeaderRow>
        <CardSummaryTitle
          title={cardData.Title}
          onClick={() =>
            navigate(ROUTE_OPPORTUNITY_DETAILS(cardData.NoticeID), {
              state: props.locationState,
            })
          }
        >
          {cardData.Title}
        </CardSummaryTitle>
        <CardTrialID>{"Notice ID: " + cardData.NoticeID}</CardTrialID>
        <CardSummaryDescription title={cardData.Summary}>
          {cardData.Summary}
        </CardSummaryDescription>
      </CardSummary>

      <CardDivider />

      <CardDetails>
        <CardDetailsHeaderRow>
          <div style={{ display: "flex", flexDirection: "row", gap: "20px" }}>
            <div title={cardData.ProjectEndDate}>
              {`Project End Date: ${cardData.ProjectEndDate}`}
            </div>
            <div title={cardData.PublishedDate}>
              {`Published Date: ${cardData.PublishedDate}`}
            </div>
          </div>

          {/* TODO: remove demo-data and add real links */}
          {/* <Download
            handleDownloadClick={() => {
              console.log("Click");
              window.open(cardData.Link, "_blank");
            }}
            isDownloadDropdown={false}
          /> */}

          {/* TODO: link up to the url property for each opportunity */}
          {cardData.Link ? (
            <ClickableTextButton type="button">
              <Link to={cardData.Link} target="_blank">
                Link &nbsp;
                <BiSolidRightArrow style={{ color: "#2D6291", height: "0.8em" }} />
              </Link>
              
            </ClickableTextButton>
          ) : (
            <ClickableTextButton type="button" disabled={true}>
              <p>Link</p>
              <BiSolidRightArrow />
            </ClickableTextButton>
          )}
        </CardDetailsHeaderRow>

        <DetailsContainerH>
          <DetailWrapperH>
            <DetailHeading>Department/Ind Agency: </DetailHeading>
            <Detail title={cardData.DepartmentIndAgency}>
              {cardData.DepartmentIndAgency}
            </Detail>
          </DetailWrapperH>
          <DetailWrapperH>
            <DetailHeading>Sub-tier: </DetailHeading>
            <Detail title={cardData.SubTier}>{cardData.SubTier}</Detail>
          </DetailWrapperH>
          <DetailWrapperH>
            <DetailHeading>Funding awarded: </DetailHeading>
            <Detail title={cardData.FundingAwarded}>
              {cardData.FundingAwarded}
            </Detail>
          </DetailWrapperH>
          <DetailWrapperH>
            <DetailHeading>Notice Type: </DetailHeading>
            <Detail title={cardData.NoticeType}>{cardData.NoticeType}</Detail>
          </DetailWrapperH>
          <DetailWrapperH>
            <DetailHeading>Disease: </DetailHeading>
            <Detail title={cardData.Diseases}>{cardData.Diseases}</Detail>
          </DetailWrapperH>
          <DetailWrapperH>
            <DetailHeading>Technology: </DetailHeading>
            <Detail title={cardData.Technology}>{cardData.Technology}</Detail>
          </DetailWrapperH>
          <DetailWrapperH>
            <DetailHeading>Locations: </DetailHeading>
            <Detail title={cardData.Locations}>{cardData.Locations}</Detail>
          </DetailWrapperH>
        </DetailsContainerH>
      </CardDetails>
    </CardWrapper>
  );
};

export default ListItem;
