import { Breadcrumb, Link, Typography } from '../style';
import withRouter from '../../services/Utils/ComponentWrapper';
import { TrademarkLogo } from 'pages/Auth/styles/style.Auth.Screen';

const getDisplayName = (input_pathNames) => {
    // Cater for the path of list page
    if (input_pathNames.length >= 2 && input_pathNames[1] === "list") {
        input_pathNames[1] += " of all ";
        switch (input_pathNames[0]) {
            case "clinicalTrials":
                input_pathNames[1] += "Trials";
                break;
            case "vaccineAvailability":
                input_pathNames[1] += "Vaccines";
                break;
            case "funding":
                input_pathNames[1] += "Projects";
                break;
            default:
                break;
        }
    }
    return input_pathNames;
}

const Breadcrumbs = (props) => {
    const histories = props.history;
    const pathname = props.location.pathname;
    const pathNames = getDisplayName(pathname.split('/').filter(x => x));

    return (
        pathNames.length > 0
            ? <Breadcrumb>
                <span>{'ABL WISE-R'}&nbsp;<TrademarkLogo size={`12px`}/>&nbsp;{`>`}</span>
                {
                    pathNames.map((name, index) => {
                        const routeTo = `/${pathNames.slice(0, index + 1).join('/')}`;
                        const lastIndex = pathNames.length - 1 === index;

                        const tempName = name.split("-");
                        
                        let dispName = tempName.join(" ");
                        let modifiedTempName = dispName.split('%20');
                        dispName = modifiedTempName.join(" ");
                        modifiedTempName = dispName.split('%');
                        dispName = modifiedTempName.join("");

                        return (
                            lastIndex ?
                                <Typography key={index}>{dispName[0].toUpperCase() + dispName.substring(1)}</Typography>
                                :
                                <Link key={index} onClick={() => { histories(routeTo, {state: props.location.state}) }}>
                                    {dispName[0].toUpperCase() + dispName.substring(1)}
                                </Link>
                        );
                    })
                }
            </Breadcrumb>
        : <div></div>
    );
}

export default withRouter(Breadcrumbs);