import React, { lazy, Suspense, Component } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { Fallback } from "./components";
import { AuthProvider } from "./contexts/AuthContext";

import Diseases from "./pages/Disease-selection";

import PrivateRoute from "services/Utils/PrivateRoute";
import {
  ForgotPassword,
  LogInForm,
  PreRegister,
  ResetPassword,
  SetPassword,
  SignupController,
} from "./pages/Auth";
import { IndividualTrial } from "./pages/ClinicalTrials/index";
import { GaaLogo } from "pages/Assets";
import { OpportunityDetails } from "./pages/Opportunities/index";

const ClinicalTrials = lazy(() =>
  import("./pages/ClinicalTrials/index").then((module) => ({
    default: module.ClinicalTrials,
  }))
);

const Home = lazy(() => import("./pages/Home"));

const Opportunities = lazy(() =>
  import("./pages/Opportunities/index").then((module) => ({
    default: module.Opportunities,
  }))
);

class App extends Component {
  render() {
    return (
      <Suspense fallback={<Fallback />}>
        <Router>
          <AuthProvider>
            <Routes>
              <Route path="/assets/gaa-logo" element={<GaaLogo />} />

              <Route path="/login" element={<LogInForm />} />
              <Route path="/pre-register" element={<PreRegister />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/set-password/:token" element={<SetPassword />} />
              <Route
                path="/reset-password"
                element={
                  <PrivateRoute>
                    <ResetPassword />
                  </PrivateRoute>
                }
              />
              <Route path="/signup" element={<SignupController />} />

              <Route
                path="/"
                element={
                  <PrivateRoute>
                    <Home />
                  </PrivateRoute>
                }
              />
              <Route
                path="/disease-selection"
                element={
                  <PrivateRoute>
                    <Diseases />
                  </PrivateRoute>
                }
              />
              <Route
                path="/disease-selection/:disease"
                element={
                  <PrivateRoute>
                    <ClinicalTrials />
                  </PrivateRoute>
                }
              />
              <Route
                path="/disease-selection/:disease/:trialId"
                element={
                  <PrivateRoute>
                    <IndividualTrial />
                  </PrivateRoute>
                }
              />

              <Route
                path="/opportunities"
                element={
                  <PrivateRoute>
                    <Opportunities />
                  </PrivateRoute>
                }
              />
              <Route
                path="/opportunities/:opportunityId"
                element={
                  <PrivateRoute>
                    <OpportunityDetails />
                  </PrivateRoute>
                }
              />
              <Route path="*" element={<Navigate to={"/"} />} />
            </Routes>
          </AuthProvider>
        </Router>
      </Suspense>
    );
  }
}

export default App;
