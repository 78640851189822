import React, { useEffect } from "react";
import Layout from "layout/opportunitiesLayout";
import { useState } from "react";
import { fetchData, fetchFilters } from "./utils/requests";
import { useDispatch } from "react-redux";
import { TopScrollBtn } from "components";
import { useAuth } from "contexts/AuthContext";
import List from "./components/List";
import { useNavigate } from "react-router";
import useFilter from "./hooks/useFilter";

function Opportunities(props) {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);
  const { currentUser, userLogOut } = useAuth();
  const [searchText, setSearchText] = useState("");

  const [data, setData] = useState({
    currentPage: 1,
    pageCount: 1,
    countOfFilteredProjects: 0,
    list: [],
  });

  const {
    filters,
    setFilterByKey,
    resetFilters,
    options,
    setOptionsWithResults,
  } = useFilter();

  useEffect(() => {
    queryFilteredData();
  }, [filters]);

  const queryFilteredData = (newPage = data.currentPage) => {
    const pageToQuery = newPage == data.currentPage ? 1 : newPage;
    setLoading(true);

    // const filterJoined = {
    //     Disease: [...filtersSelected.Disease.slice(0), location.state],
    //     Sponsor: [...filtersSelected.Sponsor.map((e) => e.replace("'", "%27"))],
    //     Location: [
    //         ...new Set([
    //             ...filtersSelected.Locations,
    //             ...filtersSelected.SurroundCountries,
    //         ]),
    //     ],
    //     Phase: [...filtersSelected.Phase],
    //     Active: [...filtersSelected.Active],
    //     StartYear: [...filtersSelected.Year_Started],
    //     StudyType: [
    //         ...filtersSelected.Study_Type.map((e) => e.replace("'", "%27")),
    //     ],
    //     CompletionYear: [...filtersSelected.Year_Completed],
    // };

    let filtersData = JSON.parse(JSON.stringify(filters));
    filtersData["Search"] = searchText;

    const dataString = JSON.stringify({
      filters: filtersData,
      pageNum: pageToQuery,
    });

    fetchFilters(dataString, currentUser, userLogOut)
      .then((results) => {
        setOptionsWithResults(results);
      })
      .catch((err) =>
        console.error(`Error Occurred during fetch: ${err.message}`)
      )
      .finally(() => setLoading(false));

    fetchData(dataString, currentUser, userLogOut)
      .then((results) => {
        console.log(results.list);
        setData((prevData) => ({
          ...prevData,
          pageCount: results.totalPageCount,
          countOfFilteredProjects: results.totalCount,
          list: results.list,
          currentPage: pageToQuery,
        }));
      })
      .catch((err) =>
        console.error(`Error Occurred during fetch: ${err.message}`)
      )
      .finally(() => setLoading(false));
  };

  const paginate = (number) => {
    queryFilteredData(number);
    scrollToListContent();
  };

  const scrollToListContent = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <Layout
      isLoaderDisplay={isLoading}
      footerRelative={true}
      handleSearchSubmit={queryFilteredData}
      handleSearchInput={(e) => setSearchText(e.target.value)}
      onBackClickCallback={() => navigate("/")}
      filterOptions={options}
      filtersSelected={filters}
      handleClickFilters={setFilterByKey}
      handleResetFilters={resetFilters}
    >
      {data.pageCount > 0 && (
        <TopScrollBtn
          color="#2D6291"
          label={"To Top"}
          handleOnClick={scrollToListContent}
        />
      )}

      <List data={data} paginate={paginate} />
    </Layout>
  );
}

export default Opportunities;
