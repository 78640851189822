import { useState } from 'react';

export const defaultFilters = {
    Published_Year: [], 
    Project_End_Year: [],
    Funded: [],
    Status: [ "Active" ], // show open opportunities by default
    Department: [],
    // Location: [],
    Recipient: [],
    // Recipient_Type: [],
    Diseases: [],
    Technology: [],
    // Notice_Type: [],
};

const useFilter = () => {

    const [ filters, setFilters ] = useState(defaultFilters);
    const [ options, setOptions ] = useState(defaultFilters);

    const setFilterByKey = (key, value) => {
        setFilters(prev => ({
            ...prev,
            [key]: value
        }))
    }

    const setOptionsWithResults = (results) => {
        const newOptions = JSON.parse(JSON.stringify(options))
        Object.keys(newOptions).forEach((key) => {
            if (key in results) newOptions[key] = results[key];
        })
        setOptions(newOptions);
    }

    const resetFilters = () => {
        const emptyFilters = Object.assign({}, defaultFilters);
        Object.keys(emptyFilters).forEach((key) => {
            emptyFilters[key] = [];
        })
        setFilters(emptyFilters);
    }

    return {
        filters,
        setFilterByKey,
        resetFilters,
        options,
        setOptionsWithResults
    };
}

export default useFilter;
