import { getAPIParams } from "middleware/api.js";
import { getEncryptObj } from "services/Utils/encrypt.js";

export const fetchFilters = async (dataString, currentUser, userLogOut) => {
    const [ MODE, PATH, PORT ] = getAPIParams("ABL");

    const encryptObj = await getEncryptObj(dataString, "ABL");
    const rawResponse = await fetch(
        `${MODE}://${PATH}:${PORT}/v1/opportunities/filter?iv=${encryptObj["iv"]}&encryptedData=${encryptObj["encryptedData"]}`,
        {
            method: "GET",
            headers: {
                Authorization: `${currentUser.token}`,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        }
    );

    const results = await rawResponse.json();
    return results;
};

export const fetchData = async (dataString, currentUser, userLogOut) => {
    const [ MODE, PATH, PORT ] = getAPIParams("ABL");
    const encryptObj = await getEncryptObj(dataString, "ABL");
    const rawResponse = await fetch(
        `${MODE}://${PATH}:${PORT}/v1/opportunities?iv=${encryptObj["iv"]}&encryptedData=${encryptObj["encryptedData"]}`,
        {
            method: "GET",
            headers: {
                Authorization: `${currentUser.token}`,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        }
    );

    // Receive filtered results
    const results = await rawResponse.json();
    return results;
};

export const queryOpportunityData = async (opportunityId, currentUser, userLogOut) => {
    const [ MODE, PATH, PORT ] = getAPIParams("ABL");
    const rawResponse = await fetch(
        // For local-file use: window.location.origin + `/ct_data.json`,
        `${MODE}://${PATH}:${PORT}/v1/opportunities/detail/` + opportunityId,
        {
            method: "GET",
            headers: {
                Authorization: `${currentUser.token}`,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        }
    );

    const results = await rawResponse.json();
    return results;
}
